import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import Swiper from 'react-id-swiper';
import LocalImage from '../../common/LocalImage';

import styles from './HomeClients.module.scss';
import '../../../../node_modules/react-id-swiper/lib/styles/scss/swiper.scss';
import ClientLogo1Url from '../../../images/home/home-client-1-logo.svg';
import ClientLogo2Url from '../../../images/home/home-client-2-logo.svg';

const HomeClients = () => {
  const sliderClients = [
    {
      id: 1,
      photo: 'home/home-client-1.jpg',
      photoMobile: 'home/home-client-1-m.jpg',
      text:
        '“Zed ut perspiciatis, unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam eaque ipsa, quae ab illo inventore veritatis et quasi.”',
      author: 'Thomas Stock',
      position: 'Top Manager',
      logo: ClientLogo1Url,
    },
    {
      id: 2,
      photo: 'home/home-client-2.jpg',
      photoMobile: 'home/home-client-2-m.jpg',
      text:
        '“Sed ut perspiciatis, unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam eaque ipsa, quae ab illo inventore veritatis et quasi.”',
      author: 'Steven Thompson',
      position: 'Top Manager',
      logo: ClientLogo2Url,
    },
    {
      id: 3,
      photo: 'home/home-client-1.jpg',
      photoMobile: 'home/home-client-1-m.jpg',
      text:
        '“Zed ut perspiciatis, unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam eaque ipsa, quae ab illo inventore veritatis et quasi.”',
      author: 'Thomas Stock',
      position: 'Top Manager',
      logo: ClientLogo1Url,
    },
    {
      id: 4,
      photo: 'home/home-client-2.jpg',
      photoMobile: 'home/home-client-2-m.jpg',
      text:
        '“Zed ut perspiciatis, unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam eaque ipsa, quae ab illo inventore veritatis et quasi.”',
      author: 'Steven Thompson',
      position: 'Top Manager',
      logo: ClientLogo2Url,
    },
  ];
  const [swiper, updateSwiper] = useState(null);
  const [isBeginning, updateIsBeginning] = useState(1);
  const [isEnd, updateisEnd] = useState(0);
  const params = {
    slidesPerView: 1,
    effect: 'fade',
    getSwiper: updateSwiper,
  };
  useEffect(() => {
    if (swiper !== null) {
      swiper.on('slideChange', () => {
        updateIsBeginning(swiper.isBeginning);
        updateisEnd(swiper.isEnd);
      });
    }
  }, [swiper]);
  const goPrev = () => {
    if (swiper !== null) {
      if (!isBeginning) {
        swiper.slidePrev();
      }
    }
  };
  const goNext = () => {
    if (swiper !== null) {
      if (!isEnd) {
        swiper.slideNext();
      }
    }
  };

  return (
    <section className={styles.sectionHomeClients}>
      <h2>
        Our happy <strong>clients</strong>
      </h2>

      <div className={styles.sliderWrapper}>
        <Swiper getSwiper={updateSwiper} {...params}>
          {sliderClients.map(({ id, photo, photoMobile, text, author, position, logo }) => {
            // const sliderStyle = { backgroundImage: `url(${photo})` };

            return (
              <div key={id} className={styles.slideWrapper}>
                <div className={styles.photo}>
                  <LocalImage src={photo} srcSet={`${photoMobile} 575w, ${photo} 576w`} />
                </div>
                <div className={styles.info}>
                  <div className={styles.quote}>{text}</div>
                  <div className={styles.author}>{author}</div>
                  <div className={styles.position}>{position}</div>
                  <div className={styles.logo}>
                    <img src={logo} alt="" height={15} />
                  </div>
                </div>
              </div>
            );
          })}
        </Swiper>
        <div className={styles.swiperNav}>
          <span
            role="button"
            tabIndex={0}
            className={classnames(styles.swiperPrev, isBeginning && styles.disabled)}
            onClick={goPrev}
            onKeyDown={goPrev}
          >
            <span />
          </span>
          <span
            role="button"
            tabIndex={0}
            className={classnames(styles.swiperNext, isEnd && styles.disabled)}
            onClick={goNext}
            onKeyDown={goNext}
          >
            <span />
          </span>
        </div>
      </div>
    </section>
  );
};

export default HomeClients;
