import { useEffect, useState } from 'react';
import useGraphqlRequest from 'hooks/useGraphqlRequest';
import _ from 'lodash';

const GET_LIST_ITEM_QUERY = `
  query businessesSample($country: String!, $city: String!, $category: String!, $businessesCount: Int) {
    businessesSample(country: $country, city: $city, category: $category, businessesCount: $businessesCount) {
      _id
      name
      location {
        city
        country
        countryCode
        address
        prettyLocation
        zipCode
      }
      phoneNumbers {
        local
      }
      urls {
        webSiteUrl
      }
      social {
        type
        link
      }
      rating
      reviewCount
      contacts {
        email
        fullName
      }
    }
  }
`;

const useListItemInfo = (props) => {
  const { country, city, category } = props;
  const getList = useGraphqlRequest(GET_LIST_ITEM_QUERY);

  const [loading, setLoading] = useState(true);
  const [list, setList] = useState(null);

  useEffect(() => {
    if (city && category) {
      const getOptions = async () => {
        const data = await getList({ country, city, category, businessesCount: 25 });
        setLoading(false);
        const result = _.get(data, 'businessesSample', null);
        setList(result);
      };
      getOptions();
    }
  }, [country, city, category]);

  return [list, loading];
};

export default useListItemInfo;
