import React, { useCallback, useState, useMemo } from 'react';
import AsyncReactSelect from 'react-select/async';
import wrapFormGroup from './wrapFormGroup';

const AsyncSelect = (props) => {
  const { onChange = () => {}, loadOptions, value, ...rest } = props;
  const [options, setOptions] = useState([]);

  const loadOptionsWrapper = useCallback(
    (input, callback) => {
      const newCallback = (newOptions) => {
        setOptions(newOptions);
        callback(newOptions);
      };

      loadOptions(input, newCallback);
    },
    [loadOptions],
  );

  const noOptionsFunction = useCallback(({ inputValue }) => (inputValue ? 'Not found' : 'Type to see options'), []);

  const onChangeOnlyValue = useCallback(({ value: newValue }, action) => onChange(newValue, action), [onChange]);

  const valueFromOptions = useMemo(() => {
    return options.find((o) => o.value === value) || null;
  }, [options, value]);
  const menuPortalTarget = useMemo(() => {
    try {
      return document.body;
    } catch (error) {
      return undefined;
    }
  });

  return (
    <>
      <AsyncReactSelect
        onChange={onChangeOnlyValue}
        hideSelectedOptions={false}
        loadOptions={loadOptionsWrapper}
        cacheOptions
        noOptionsMessage={noOptionsFunction}
        value={valueFromOptions}
        menuPlacement="auto"
        menuPortalTarget={menuPortalTarget}
        {...rest}
      />
    </>
  );
};

export default wrapFormGroup(AsyncSelect);
