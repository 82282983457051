import React, { useCallback } from 'react';
import { useField } from 'formik';
// reactstrap components
// import isNil from 'lodash/isNil';

const wrapField = (C) => {
  const Wrapped = ({ field, form, innerRef, onBlur = () => {}, hideError, ...props }) => {
    const [, meta] = useField(field.name);
    const error = meta.touched && meta.error;
    const handleChange = React.useCallback(
      (value) => {
        form.setFieldValue(field.name, value);
      },
      [field.name, form],
    );

    const handleBlur = useCallback(
      (e) => {
        form.setTouched({ [field.name]: true });
        onBlur(e);
      },
      [field.name, form, onBlur],
    );

    return (
      <C
        ref={innerRef}
        {...props}
        {...field}
        onChange={handleChange}
        error={hideError ? false : error}
        onBlur={handleBlur}
      />
    );
  };
  Wrapped.displayName = `WrappedField(${C.displayName || C.name})`;
  return Wrapped;
};

export default wrapField;
