import React, { useCallback, useMemo } from 'react';
import ReactSelect from 'react-select';

const customStyles = {
  option: (provided) => ({
    ...provided,
    fontSize: '14px',
    color: '#33325C',
  }),
  control: (provided, state) => ({
    ...provided,
    borderRadius: '50px',
    border: '1px solid #F2F5FE',
    background: state.isDisabled ? '#F4F6FA' : '#fff',
    height: '40px',
    fontSize: '14px',
    paddingLeft: '10px',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#BFC3D7',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#33325C',
    border: 'none',
    padding: '0 20px 0 0',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
};

const Select = (props) => {
  const {
    value,
    options, // array of { value, label }
    onChange, // function that is called after value is changed by user
    defaultValue, // optional: onChange will be called immediately with that value, if `value` is not in `options`
    hasError,
    styles = { ...customStyles },
    ...rest
  } = props;
  const handleChange = useCallback((selectedOption, actions) => onChange(selectedOption.value, actions), [onChange]);
  const transformedValue = useMemo(() => options.find((o) => o.value === value) || null, [options, value]);

  const menuPortalTarget = useMemo(() => {
    try {
      return document.body;
    } catch (error) {
      return undefined;
    }
  });

  return (
    <ReactSelect
      value={transformedValue}
      onChange={handleChange}
      options={options}
      //   styles={mergeStyles(defaultStyles, styles)}
      styles={styles}
      {...rest}
      menuPlacement="auto"
      menuPortalTarget={menuPortalTarget}
    />
  );
};
const forwardedSelect = React.forwardRef(Select);

export default forwardedSelect;
