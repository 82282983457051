import React from 'react';
import { useTable, useSortBy, useExpanded } from 'react-table';

export default function Table({
  isExpandable = false,
  className,
  columns,
  data,
  getRowProps = () => {},
  renderRowSubComponent,
  ...rest
}) {
  const tableData = useTable(
    {
      columns,
      data,
      ...rest,
    },
    useSortBy,
    isExpandable && useExpanded,
  );
  const { getTableProps, getTableBodyProps, rows, prepareRow, visibleColumns, headers } = tableData;
  return (
    <>
      <table className={className} {...getTableProps()}>
        <thead>
          <tr>
            {headers.map((column) => (
              <th {...column.getHeaderProps(column.isSortable && column.getSortByToggleProps())}>
                {column.render('Header')}
                <span>{column.isSorted && (column.isSortedDesc ? ' ▼' : ' ▲')}</span>
              </th>
            ))}
          </tr>
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            const {
              original: { _id },
            } = row;
            return (
              <React.Fragment key={_id}>
                <tr {...row.getRowProps(getRowProps(row))}>
                  {row.cells.map((cell) => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                  })}
                </tr>
                {row.isExpanded ? (
                  <tr>
                    <td colSpan={visibleColumns.length}>{renderRowSubComponent({ row })}</td>
                  </tr>
                ) : null}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    </>
  );
}
