import { useCallback } from 'react';
import get from 'lodash/get';
import useGraphqlRequest from 'hooks/useGraphqlRequest';
// import categories from './__mocks/categories';

const GET_CATEGORIES = `
  query categories($search: String) {
    categories(search: $search)
  }
`;

const useSearchCategories = () => {
  const getCategories = useGraphqlRequest(GET_CATEGORIES);
  const searchCategories = useCallback(async (search = '', callback) => {
    const data = await getCategories({ search });
    const categories = get(data, 'categories', []);
    callback(categories.map((category) => ({ value: category, label: category })));
  }, []);
  return searchCategories;
};

export default useSearchCategories;
