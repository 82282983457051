import { useState, useEffect } from 'react';
import get from 'lodash/get';
import useGraphqlRequest from 'hooks/useGraphqlRequest';

const GET_CITIES = `
	query cities($country: String!) {
		cities(country: $country)
	}
`;

const useSearchCities = (country) => {
  const getCities = useGraphqlRequest(GET_CITIES);
  const [loading, setLoading] = useState(false);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    if (country) {
      setLoading(true);
      const getOptions = async () => {
        const data = await getCities({ country }).catch(() => setLoading(false));
        setLoading(false);
        const result = get(data, 'cities', []).map((city) => ({ value: city, label: city }));
        setCities(result);
      };
      getOptions();
    }
  }, [country]);

  return [cities, loading];
};

export default useSearchCities;
