import { useState, useEffect } from 'react';
import { countries } from 'countries-list';
import useGraphqlRequest from 'hooks/useGraphqlRequest';
import get from 'lodash/get';

const GET_COUNTRIES = `
  query countries {
    countries
  }
`;

const useSearchCountries = () => {
  const getCountries = useGraphqlRequest(GET_COUNTRIES);
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const getOptions = async () => {
      const data = await getCountries().catch(() => setLoading(false));
      setLoading(false);
      const result = get(data, 'countries', []).map((country) => ({ value: country, label: countries[country].name }));
      setOptions(result);
    };
    getOptions();
  }, []);

  return [options, loading];
};

export default useSearchCountries;
