import React from 'react';
import { Row, Col } from 'react-grid-system';
import styles from './HomeFeatures.module.scss';
import OkIconUrl from '../../../images/icons/ok.svg';
import FeaturesImg1Url from '../../../images/home/home-features-1.svg';
import FeaturesImg2Url from '../../../images/home/home-features-2.svg';

const HomeFeatures = () => {
  return (
    <section className={styles.sectionHomeFeatures}>
      <Row gutterWidth={20}>
        <Col lg={6}>
          <div className={styles.featuresBlock}>
            <div className={styles.img}>
              <img src={FeaturesImg1Url} alt="" />
            </div>
            <h3 className={styles.title}>What you get</h3>
            <ul className={styles.featureList}>
              <li>
                <img src={OkIconUrl} alt="" className={styles.icon} />
                <span className={styles.text}>Sed ut perspiciatis, unde omnis iste natus</span>
              </li>
              <li>
                <img src={OkIconUrl} alt="" className={styles.icon} />
                <span className={styles.text}>Sed ut perspiciatis, unde omnis iste natus</span>
              </li>
              <li>
                <img src={OkIconUrl} alt="" className={styles.icon} />
                <span className={styles.text}>We also have emails that are manualy checked!</span>
              </li>
              <li>
                <img src={OkIconUrl} alt="" className={styles.icon} />
                <span className={styles.text}>Sed ut perspiciatis, unde omnis iste natus</span>
              </li>
              <li>
                <img src={OkIconUrl} alt="" className={styles.icon} />
                <span className={styles.text}>All of our emails are verified! (Zero bounce)</span>
              </li>
              <li>
                <img src={OkIconUrl} alt="" className={styles.icon} />
                <span className={styles.text}>Sed ut perspiciatis, unde omnis iste natus</span>
              </li>
            </ul>
          </div>
        </Col>
        <Col lg={6}>
          <div className={styles.featuresBlock}>
            <div className={styles.img}>
              <img src={FeaturesImg2Url} alt="" />
            </div>
            <h3 className={styles.title}>Why Boost?</h3>
            <ul className={styles.featureList}>
              <li>
                <img src={OkIconUrl} alt="" className={styles.icon} />
                <span className={styles.text}>We always update our Data Base</span>
              </li>
              <li>
                <img src={OkIconUrl} alt="" className={styles.icon} />
                <span className={styles.text}>Sed ut perspiciatis, unde omnis iste natus</span>
              </li>
              <li>
                <img src={OkIconUrl} alt="" className={styles.icon} />
                <span className={styles.text}>We also have emails that are manualy checked!</span>
              </li>
              <li>
                <img src={OkIconUrl} alt="" className={styles.icon} />
                <span className={styles.text}>Sed ut perspiciatis, unde omnis iste natus</span>
              </li>
              <li>
                <img src={OkIconUrl} alt="" className={styles.icon} />
                <span className={styles.text}>All of our emails are verified! (Zero bounce)</span>
              </li>
            </ul>
          </div>
        </Col>
      </Row>
    </section>
  );
};

export default HomeFeatures;
