import { useCallback } from 'react';
import axios from 'axios';
import { demoWidgetServerUrl } from '../config/settings';

const URL = demoWidgetServerUrl;

const useGraphqlRequest = (query, variables = {}) => {
  const request = useCallback(async (newVariables) => {
    const res = await axios
      .post(
        URL,
        JSON.stringify({
          query,
          variables: newVariables || variables,
        }),
        {
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
          },
        },
      )
      .catch((e) => console.log('ERROR: ', e));
    return res.data.data;
  }, []);

  return request;
};

export default useGraphqlRequest;
