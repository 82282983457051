import React, { useState, useEffect } from 'react';
import Swiper from 'react-id-swiper';
import classnames from 'classnames';
// import { Swiper, Navigation } from 'swiper/js/swiper.esm';
// import ReactIdSwiperCustom from 'react-id-swiper/lib/ReactIdSwiper.custom';
import LocalImage from '../../common/LocalImage';

import styles from './HomeTrust.module.scss';
import '../../../../node_modules/react-id-swiper/lib/styles/scss/swiper.scss';

const HomeTrust = () => {
  const sliderImages = [
    {
      id: 1,
      url: 'partners/l1.png',
      width: 80,
    },
    {
      id: 2,
      url: 'partners/l2.png',
      width: 80,
    },
    {
      id: 3,
      url: 'partners/l3.png',
      width: 100,
    },
    {
      id: 4,
      url: 'partners/l4.png',
      width: 84,
    },
    {
      id: 5,
      url: 'partners/l5.png',
      width: 103,
    },
    {
      id: 6,
      url: 'partners/l6.png',
      width: 86,
    },
    {
      id: 7,
      url: 'partners/l7.png',
      width: 100,
    },
    {
      id: 8,
      url: 'partners/l8.png',
      width: 130,
    },
    {
      id: 9,
      url: 'partners/l9.png',
      width: 124,
    },
    {
      id: 10,
      url: 'partners/l10.png',
      width: 107,
    },
    {
      id: 11,
      url: 'partners/l11.png',
      width: 115,
    },
    {
      id: 12,
      url: 'partners/l12.png',
      width: 124,
    },
  ];

  const [swiper, updateSwiper] = useState(null);
  const [isBeginning, updateIsBeginning] = useState(1);
  const [isEnd, updateisEnd] = useState(0);
  const params = {
    slidesPerView: 4,
    slidesPerColumn: 2,
    // modules: [Navigation],
    // navigation: {
    //   prevEl: '.swiper-button-prev',
    //   nextEl: '.swiper-button-next',
    // },
    spaceBetween: 5,
    breakpoints: {
      992: {
        slidesPerView: 4,
      },
      768: {
        slidesPerView: 3,
        slidesPerColumn: 2,
      },
      576: {
        slidesPerView: 2,
        slidesPerColumn: 2,
      },
      0: {
        slidesPerView: 2,
        slidesPerColumn: 1,
      },
    },
    getSwiper: updateSwiper,
  };

  useEffect(() => {
    if (swiper !== null) {
      swiper.on('slideChange', () => {
        updateIsBeginning(swiper.isBeginning);
        updateisEnd(swiper.isEnd);
      });
    }
  }, [swiper]);
  const goPrev = () => {
    if (swiper !== null) {
      if (!isBeginning) {
        swiper.slidePrev();
      }
    }
  };
  const goNext = () => {
    if (swiper !== null) {
      if (!isEnd) {
        swiper.slideNext();
      }
    }
  };

  return (
    <section className={styles.sectionHomeTrust}>
      <h2>
        <strong>Trust</strong> us
      </h2>

      <div className={styles.sliderWrapper}>
        <Swiper getSwiper={updateSwiper} {...params}>
          {sliderImages.map(({ id, url, width }) => (
            <div key={id} className={styles.imgWrapper}>
              <div className={styles.imgWrapperInner} style={{ width }}>
                <LocalImage src={url} />
              </div>
            </div>
          ))}
        </Swiper>
        <div className={styles.swiperNav}>
          <span
            role="button"
            tabIndex={0}
            className={classnames(styles.swiperPrev, isBeginning && styles.disabled)}
            onClick={goPrev}
            onKeyDown={goPrev}
          >
            <span />
          </span>
          <span
            role="button"
            tabIndex={0}
            className={classnames(styles.swiperNext, isEnd && styles.disabled)}
            onClick={goNext}
            onKeyDown={goNext}
          >
            <span />
          </span>
        </div>
      </div>
    </section>
  );
};

export default HomeTrust;
