import React from 'react';
import Select from 'components/form/formik/Select';
import useSearchCities from './useSearchCities';

const CitySearchSelect = (props) => {
  const {
    form: {
      values: { country },
    },
  } = props;

  const [options, loading] = useSearchCities(country);

  // const loadCityOptions = useCallback((input, callback) => loadOptions(input, callback, country), [
  //   country,
  //   loadOptions,
  // ]);

  return <Select {...props} options={options} isLoading={loading} isDisabled={!country} />;
};

export default CitySearchSelect;
