import React from 'react';
import { ErrorMessage } from 'formik';
import Select from '../Select';
import FormFeedback from '../FormFeedback';

const FormSelect = (props) => {
  const { field, form, ...inputProps } = props;
  const { label, className, required, disabled, onChange = () => {}, hideError, ...rest } = inputProps;
  const hasErrorAndTouched = form.errors[field.name] && (form.touched[field.name] || form.submitCount);

  const handleSelectChange = React.useCallback(
    (value) => {
      onChange(value, form);
      form.setFieldValue(field.name, value);
    },
    [form, onChange, field.name],
  );
  const { onBlur, name } = field;
  return (
    <>
      <Select
        className={`${className} ${hasErrorAndTouched && 'error-input'}`}
        isDisabled={disabled}
        hasError={hasErrorAndTouched}
        {...field}
        {...rest}
        onChange={handleSelectChange}
        onBlur={(e) => {
          e.target.id = name;
          onBlur(e);
        }} // fix {touched: {react-select-1-input:true}}
      />
      {!hideError ? (
        <ErrorMessage
          name={field.name}
          component={FormFeedback}
          invalid={hasErrorAndTouched}
          style={{ display: 'block' }}
        />
      ) : null}
    </>
  );
};
export default FormSelect;
