import React from 'react';
import pluralize from 'pluralize';
import Rating from 'react-rating';
import Table from 'components/tables/table';
import get from 'lodash/get';
import { ReactComponent as FullStar } from 'assets/icons/full-star.svg';
import { ReactComponent as EmptyStar } from 'assets/icons/empty-star.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons';
// import {
//   faTwitter,
//   faFacebookSquare,
//   faLinkedin,
//   faInstagramSquare,
//   faPinterestSquare,
// } from '@fortawesome/free-brands-svg-icons';
import BlurredString from 'components/BlurredString';
import BusinessContactsSubTable from './BusinessContactsSubTable';
import styles from './ListItemInfo.module.scss';

// const getSocialIcon = (socialName) => {
//   switch (socialName) {
//     case 'facebook':
//       return faFacebookSquare;
//     case 'instagram':
//       return faInstagramSquare;
//     case 'linked_in':
//       return faLinkedin;
//     case 'twitter':
//       return faTwitter;
//     case 'pinterest':
//       return faPinterestSquare;
//     default:
//       return '';
//   }
// };

// const renderSocials = ({ cell }) => {
//   const { value } = cell;
//   const icons = value.map(({ type, link }) => {
//     const socialIcon = getSocialIcon(type);
//     return (
//       // eslint-disable-next-line react/jsx-no-target-blank
//       <a key={`${type}-${link}`} href={link} target="_blank">
//         <FontAwesomeIcon icon={socialIcon} />
//       </a>
//     );
//   });
//   return icons;
// };

const renderRating = ({ row }) => {
  const {
    original: { rating, reviewCount },
  } = row;
  return (
    <span>
      <Rating readonly fractions={2} initialRating={rating} emptySymbol={<EmptyStar />} fullSymbol={<FullStar />} />
      <div>
        {reviewCount} {pluralize('Review', reviewCount)}
      </div>
    </span>
  );
};

const BusinessTable = (props) => {
  const { businessUnits } = props;

  const columns = React.useMemo(
    () => [
      {
        // Make an expander cell
        Header: () => null, // No header
        id: 'expander', // It needs an ID
        Cell: ({ row }) => (
          // Use Cell to render an expander for each row.
          // We can use the getToggleRowExpandedProps prop-getter
          // to build the expander.
          <span {...row.getToggleRowExpandedProps()}>
            <FontAwesomeIcon icon={row.isExpanded ? faCaretDown : faCaretRight} />
          </span>
        ),
      },
      {
        Header: 'Website',
        accessor: 'urls.webSiteUrl',
        Cell: ({ row: { original } }) => <BlurredString string={get(original, 'urls.webSiteUrl', '')} />,
        isSortable: true,
      },
      {
        Header: 'Business name',
        accessor: 'name',
        isSortable: true,
        Cell: ({ row: { original } }) => <BlurredString string={get(original, 'name', '')} />,
      },
      {
        Header: 'City',
        accessor: 'location.city',
        isSortable: true,
      },
      {
        Header: 'Full Address',
        accessor: 'location.prettyLocation',
        isSortable: false,
        Cell: ({ row: { original } }) => <BlurredString string={get(original, 'location.prettyLocation', '')} />,
      },
      {
        Header: 'Zip',
        accessor: 'location.zipCode',
        isSortable: false,
        Cell: ({ row: { original } }) => <BlurredString string={get(original, 'location.zipCode', '')} />,
      },
      {
        Header: 'Phone Number',
        accessor: 'phoneNumbers.local',
        isSortable: false,
        Cell: ({ row: { original } }) => <BlurredString string={get(original, 'phoneNumbers.local', '')} />,
      },
      // {
      //   Header: 'Social Accounts',
      //   accessor: 'social',
      //   isSortable: false,
      //   Cell: renderSocials,
      // },
      {
        Header: '',
        accessor: 'rating',
        isSortable: false,
        Cell: renderRating,
      },
    ],
    [],
  );

  // Create a function that will render our row sub components

  const renderRowSubComponent = ({ row }) => <BusinessContactsSubTable contacts={row.original.contacts || []} />;

  const getRowProps = React.useCallback((row) => {
    const {
      original: { _id },
    } = row;
    return {
      key: _id,
    };
  }, []);

  return (
    <>
      <Table
        isExpandable
        autoResetExpanded={false}
        className={styles.listItemTable}
        columns={columns}
        data={businessUnits}
        getRowProps={getRowProps}
        renderRowSubComponent={renderRowSubComponent}
      />
    </>
  );
};

export default BusinessTable;
