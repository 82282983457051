import React from 'react';
import FormFeedback from 'components/form/FormFeedback';
import FormText from 'components/form/FormText';
import Label from './Label/Label';
import styles from './FormGroup.module.scss';

const FormGroupEnhanced = ({ label, name, id, required, children, error, hint, labelAfter, labelInline }) => {
  return (
    <div className={labelInline ? styles.labelInline : ''}>
      {label && !labelAfter ? (
        <Label htmlFor={id || name} required={required}>
          {label}
        </Label>
      ) : null}
      {hint ? <FormText>{hint}</FormText> : null}
      {children}
      {label && labelAfter ? (
        <Label htmlFor={id || name} required={required}>
          {label}
        </Label>
      ) : null}
      {error ? (
        <FormFeedback valid={false} style={{ display: 'block' }}>
          {error}
        </FormFeedback>
      ) : null}
    </div>
  );
};

export default FormGroupEnhanced;
