import React from 'react';
import useListItemInfo from 'hooks/useListItemInfo';
// import Loading from 'components/common/Loading';
import BusinessTable from './BusinessTable';
import styles from './ListItemInfo.module.scss';

// props: {country, city, category }
const ListItemInfo = (props) => {
  const [businessUnits] = useListItemInfo(props);

  if (!businessUnits) return null;

  return (
    <>
      <div className={styles.listItemInfo}>
        <BusinessTable businessUnits={businessUnits} />
      </div>
    </>
  );
};

export default ListItemInfo;
