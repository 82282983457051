import React from 'react';
import './BlurredString.scss';

const BlurredString = ({ string }) => {
  const htmlString = (string || '').replace(
    /\^/g,
    `<span style="color:transparent;text-shadow: 0 0 6px #737373;">a</span>`,
  );

  // eslint-disable-next-line react/no-danger
  return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
};

export default BlurredString;
