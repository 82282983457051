import React from 'react';
import Typist from 'react-typist';
import RButton from '../../common/RButton';
import ImgHomeUrl from '../../../images/home/home-top.svg';
import { ReactComponent as IconNoCard } from '../../../images/icons/no-card.svg';
import styles from './HomeHero.module.scss';
import { signUpUrl } from '../../../config/settings';
import 'react-typist/dist/Typist.css';

const typingTexts = ['business & faster', 'faster & business'];

const HomeHero = () => {
  const [killTypingAnimation, setKillTypingAnimation] = React.useState(true);
  React.useEffect(() => {
    if (killTypingAnimation) setKillTypingAnimation(false);
  }, [killTypingAnimation]);
  return (
    <section className={styles.sectionHomeHero}>
      <div className={styles.heroInner}>
        <div className={styles.info}>
          <h1>
            Build a better <br />
            {!killTypingAnimation ? (
              <Typist startDelay={1500} avgTypingDelay={100} onTypingDone={() => setKillTypingAnimation(true)}>
                {typingTexts.map((t) => (
                  <span key={t}>
                    {t}
                    <Typist.Backspace count={t.length} delay={3000} />
                  </span>
                ))}
              </Typist>
            ) : null}
          </h1>
          <p>
            The website business across the world under specific country city and category and outputs all the
            information about the business.
          </p>
        </div>
        <div className={styles.img}>
          <img src={ImgHomeUrl} alt="" />
        </div>
        <div className={styles.action}>
          <a href={signUpUrl}>
            <RButton color="secondary" btnSize={60} rSize={50} iconSize={9} iconType={1} btnText="Try it free" />
          </a>
          <div className={styles.nocard}>
            <IconNoCard className={styles.icon} width={20} />
            <span>No credit card required</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeHero;
