/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import styles from './Label.module.scss';

const InputLabel = ({ children, required, ...props }) => {
  return (
    <label {...props} className={styles.label}>
      {children}{' '}
      {required === false ? (
        <small>
          <i>(optional)</i>
        </small>
      ) : null}
    </label>
  );
};

export default InputLabel;
