import React from 'react';
import styles from './HomeBackgrounds.module.scss';

const HomeBackgrounds = () => (
  <div className={styles.homeBackgrounds}>
    <div className={styles.homeBg1} />
    <div className={styles.homeBg2} />
    <div className={styles.homeBg3} />
    <div className={styles.homeBg4} />
  </div>
);

export default HomeBackgrounds;
