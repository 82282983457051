import React from 'react';
import Button from '../../common/Button';
import { ReactComponent as IconNoCard } from '../../../images/icons/no-card.svg';
import BusinessSearch from './BusinessSearch';
import styles from './HomeSearch.module.scss';
import { signUpUrl } from '../../../config/settings';

const HomeSearch = () => {
  return (
    <section className={styles.sectionHomeSearch}>
      <div className={styles.searchBlock}>
        <div className={styles.searchPlaceholder}>
          <BusinessSearch />
        </div>
      </div>
      <div className={styles.action}>
        <div className={styles.actionDesc}>
          Sign up to get the full results, search filters, CSV downloads and more.
        </div>
        <a href={signUpUrl}>
          <Button color="secondary">Create a free account</Button>
        </a>
        <div className={styles.nocard}>
          <IconNoCard className={styles.icon} width={20} />
          <span>No credit card required</span>
        </div>
      </div>
    </section>
  );
};

export default HomeSearch;
