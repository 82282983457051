import React from 'react';
import Page from '../components/layout/Page';
import HomeHero from '../components/layout/home/HomeHero';
import HomeSearch from '../components/layout/home/HomeSearch';
import HomeSteps from '../components/layout/home/HomeSteps';
import HomeFeatures from '../components/layout/home/HomeFeatures';
import HomeTrust from '../components/layout/home/HomeTrust';
import HomeClients from '../components/layout/home/HomeClients';
import HomeStart from '../components/layout/home/HomeStart';
import HomeBackgrounds from '../components/layout/home/HomeBackgrounds';

const IndexPage = () => (
  <Page navInverted bgImage title="Home">
    <HomeHero />
    <HomeSearch />
    <HomeSteps />
    <HomeFeatures />
    <HomeTrust />
    <HomeClients />
    <HomeStart />
    <HomeBackgrounds />
  </Page>
);

export default IndexPage;
