import React from 'react';
// import moment from 'moment';
import Table from 'components/tables/table';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import get from 'lodash/get';
import BlurredString from 'components/BlurredString';
import styles from './ListItemInfo.module.scss';

const BusinessContactsSubTable = (props) => {
  const { contacts, removeBusinessContactFromList, showAlert, hideAlert, showModal } = props;

  const columns = React.useMemo(
    () => [
      {
        Header: 'Full name',
        accessor: 'fullName',
        Cell: ({ row: { original } }) => <BlurredString string={get(original, 'fullName', '')} />,
      },
      {
        Header: 'Role',
        Cell: () => 'CEO',
      },
      {
        Header: 'Email',
        accessor: 'email',
        Cell: ({ row: { original } }) => <BlurredString string={get(original, 'email', '')} />,
      },
      // {
      //   Header: 'Social Accounts',
      //   Cell: () => (
      //     // eslint-disable-next-line react/jsx-no-target-blank
      //     <a key="linked-in" href="https://www.linkedin.com/" target="_blank">
      //       <FontAwesomeIcon icon={faLinkedin} />
      //     </a>
      //   ),
      // },
      // {
      //   Header: 'Last email sent',
      //   Cell: () => moment().format('MMMM DD, YYYY'),
      //   show: false,
      // },
      // {
      //   Header: 'Template name',
      //   Cell: () => 'Template 1',
      //   show: false,
      // },
      // {
      //   Header: 'Response rate',
      //   Cell: () => '55%',
      //   show: false,
      // },
    ],
    [showModal, removeBusinessContactFromList, showAlert, hideAlert],
  );

  // console.log('contacts', contacts);

  return (
    <Table
      className={styles.businessContactsTable}
      columns={columns}
      data={contacts}
      getRowProps={(row) => {
        const {
          original: { _id },
        } = row;
        return {
          key: _id,
        };
      }}
    />
  );
};

export default BusinessContactsSubTable;
