import React from 'react';
import classnames from 'classnames';
import Loading from 'components/common/Pack/Loading';
import styles from './Button.module.scss';

export const colorToClassNameMap = {
  inverted: styles.inverted,
  primary: styles.primary,
  secondary: styles.secondary,
  success: styles.success,
  danger: styles.danger,
  transparent: styles.transparent,
  cancel: styles.cancel,
  default: styles.default,
};

export const variants = Object.keys(colorToClassNameMap);

// TODO: support sizes sm md lg

const Button = ({ onClick, className, color = 'primary', children, loading, as: As = 'button', ...rest }) => (
  <As
    type="button"
    className={classnames(styles.btn, colorToClassNameMap[color], className)}
    onClick={onClick}
    disabled={loading}
    {...rest}
  >
    {loading ? <Loading size={20} color="white" /> : children}
  </As>
);

export default Button;
