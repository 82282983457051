import React from 'react';
import Loader from 'react-loader-spinner';
import { CSSTransition } from 'react-transition-group';
import classnames from 'classnames';
import styles from './Loading.module.scss';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

const accent = '#007bff';

const Loading = ({ overlay, size = 50, color = accent, active = true }) => (
  <CSSTransition
    classNames="fade"
    in={active}
    timeout={{
      enter: 0,
      exit: 500,
    }}
    unmountOnExit
  >
    {() => (
      <div className={classnames({ [styles.overlay]: overlay }, styles.container)}>
        <Loader type="Oval" color={color} width={size} height={size} />
      </div>
    )}
  </CSSTransition>
);

export default Loading;
