import React from 'react';
import { Link } from 'gatsby';
import RButton from '../../common/RButton';
import styles from './HomeSteps.module.scss';
import StepImg1Url from '../../../images/home/home-step-1.svg';
import StepImg2Url from '../../../images/home/home-step-2.svg';
import StepImg3Url from '../../../images/home/home-step-3.svg';
import StepImg4Url from '../../../images/home/home-step-4.svg';

const HomeSteps = () => {
  return (
    <section className={styles.sectionHomeSteps}>
      <div className={styles.stepList}>
        <div className={styles.step}>
          <div className={styles.stepInfo}>
            <div className={styles.stepTitle}>
              <div className={styles.num}>1</div>
              <div className={styles.title}>
                Get your <br /> <strong>businesses list</strong>
              </div>
            </div>
            <div className={styles.stepDesc}>We have information of more than 15 countries and 50 categories</div>
          </div>
          <div className={styles.stepImg}>
            <img src={StepImg1Url} alt="" />
          </div>
        </div>
        <div className={styles.step}>
          <div className={styles.stepInfo}>
            <div className={styles.stepTitle}>
              <div className={styles.num}>2</div>
              <div className={styles.title}>
                Create an <strong>email campaign</strong>
              </div>
            </div>
            <div className={styles.stepDesc}>Cutomize templates, tutorials and more</div>
          </div>
          <div className={styles.stepImg}>
            <img src={StepImg2Url} alt="" />
          </div>
        </div>
        <div className={styles.step}>
          <div className={styles.stepInfo}>
            <div className={styles.stepTitle}>
              <div className={styles.num}>3</div>
              <div className={styles.title}>
                Analyze the <strong>statistics</strong> <br />
                and improve your <br />
                campaign
              </div>
            </div>
            <div className={styles.stepDesc}>
              Sed ut perspiciatis, unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam
              rem aperiam eaque ipsa, quae ab illo inventore veritatis et quasi.
            </div>
          </div>
          <div className={styles.stepImg}>
            <img src={StepImg3Url} alt="" />
          </div>
        </div>
        <div className={styles.step}>
          <div className={styles.stepInfo}>
            <div className={styles.stepTitle}>
              <div className={styles.num}>4</div>
              <div className={styles.title}>
                <strong>Grow</strong> your <br />
                business
              </div>
            </div>
            <div className={styles.stepDesc}>
              Sed ut perspiciatis, unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam
              rem aperiam eaque ipsa, quae ab illo inventore veritatis et quasi.
            </div>
          </div>
          <div className={styles.stepImg}>
            <img src={StepImg4Url} alt="" />
          </div>
        </div>
      </div>
      <div className={styles.action}>
        <span className={styles.actionTitle}>Check out our</span>
        <Link to="/pricing">
          <RButton color="secondary" btnSize={50} rSize={40} iconSize={7} iconType={1} btnText="Pricing plans" />
        </Link>
      </div>
    </section>
  );
};

export default HomeSteps;
