import React from 'react';
import FormGroup from './FormGroup';

const wrapFormGroup = (C) => {
  const Wrapped = React.forwardRef((props, ref) => {
    const { required, label, error, hint, labelAfter, labelInline, ...rest } = props;
    return (
      <FormGroup
        required={required}
        label={label}
        error={error}
        hint={hint}
        name={rest.name}
        id={rest.id}
        labelAfter={labelAfter}
        labelInline={labelInline}
      >
        <C ref={ref} {...rest} />
      </FormGroup>
    );
  });
  Wrapped.displayName = `WrappedFormGroup(${C.displayName || C.name})`;
  return Wrapped;
};

export default wrapFormGroup;
